import { notification } from 'antd';

const ToastHelper = {
  success: (title) => {
    notification.success({
      message: title,
    });
  },
  error: (title) => {
    notification.error({
      message: title,
    });
  },
};

export default ToastHelper;