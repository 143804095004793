export const AvatarIcon = ({ fill = "#454443", className }) => {
    return (

        <svg version="1.1" viewBox="0 0 2048 2048" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <path transform="translate(988,247)" d="m0 0h36l44 4 42 6 30 6 31 8 38 13 28 13 19 10 18 12 12 9 11 9 7 6 6 7 13 13 15 20 9 15 11 21 10 26 6 21 6 27 4 29 6 54 3 26 1 15v15l-3 36-6 36-4 16 1 4 6 5v2h2l11 15 5 11 4 18v21l-5 29-6 26-5 15-7 13-7 9-9 7-11 5-15 4-6 42-9 46-9 36-10 32-10 27-11 26-15 33-14 31-3 10-1 15v17l2 21 10 3 56 18 20 7 20 9 15 9 9 8 7 10 5 10 4 16 1 8 13 11 18 13 17 12 33 22 25 16 28 17 22 13 25 14 54 29 32 16 44 15 28 12 32 16 22 13 14 9 16 11 13 10 13 11 10 9 8 7 11 11 9 11 8 9 13 18 10 16 12 22 9 21 9 27 6 28 3 23-169 1h-1607l-102-1-1-2 4-17 8-32 8-24 10-23 15-29 10-15 14-19 13-15 23-23 11-9 14-11 24-16 21-12 21-10 20-8 24-7 27-7 28-10 23-12 15-9 28-17 16-10 21-13 28-17 20-12 13-8 26-15 20-12 23-13 15-9 18-10 14-8 10-6 2-2 3-43 3-5 16-8 21-10 28-11 26-8 16-4 27-4 4-3 1-6v-11l-3-17-4-12-15-33-15-32-14-32-14-31-11-27-12-36-8-30-9-44-3-12-14 1-6-4-6-7-5-16-13-30-9-25-8-28-4-24v-17l4-16 5-10 8-7 9-4h2l-4-13-6-32-3-23-2-30v-30l2-29 4-30 5-24 7-27 7-21 11-27 8-16 10-19 13-20 14-19 12-14 2-3h2l2-4 15-15 8-7 16-13 17-12 25-15 29-14 24-9 32-9 25-5 31-4z" fill={fill} />
        </svg>

    );
};

export const BackArrow = () => {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="#390039"
        />
        <path
          d="M20.3143 15H9.6857"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 9.68579L9.6857 15.0001L15 20.3144"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };