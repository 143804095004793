import React, { useEffect, useState } from "react";
import ApiKeysTable from "./ApiKeysTable/ApiKeysTable";
import CustomerAddForm from "../CustomerManagement/CustomerAddForm/CustomerAddForm";
import { Input } from "antd";
import CustomModal from "../../Components/CustomModal/CustomModal";
import ToastHelper from "../../Services/ToastHelper";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { postApi } from "../../Utils/apiService";
import { ADD_UPDATE_APIKEY } from "../../Utils/apiPath";

const ApiKeys = () => {
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [selectedapikey, setSelectedapikey] = useState(null);

    const [formData, setFormData] = useState({
        apiKey: "",
        adminApiEndpoint: "",
        userApiEndpoint: ""

    });

    useEffect(() => {
        if (selectedapikey) {
            setFormData({
                apiKey: selectedapikey.apiKeyName || "",
                adminApiEndpoint: selectedapikey.adminApiEndPoint || "",
                userApiEndpoint: selectedapikey.userApiEndPoint || "",
            });
        }
    }, [selectedapikey]);


    //   const handleFormSuccess = () => {
    //     setShowApiKeyModal(false);
    //     setSelectedapikey(null);
    //   };

    const handleAddClick = () => {
        setSelectedapikey(null);
        setFormData({ apiKey: '', adminApiEndpoint: '', userApiEndpoint: '' });
        setShowApiKeyModal(true);
    }

    const handleEditClick = (apikey) => {
        setSelectedapikey(apikey);
        setShowApiKeyModal(true);
    };

    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [recallApi, setRecallApi] = useState(false);

    const validateField = (name, value, errors) => {
        const fieldValidations = {
            apiKey: { required: "This field is required." },
            adminApiEndpoint: { required: "This field is required." },
            userApiEndpoint: { required: "This field is required." },
        };
        const validation = fieldValidations[name];
        if (validation) {
            if (!value.trim()) {
                errors[name] = validation.required;
            } else if (validation.pattern && !validation.pattern.regex.test(value)) {
                errors[name] = validation.pattern.message;
            } else {
                delete errors[name];
            }
        }
    };


    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        let errors = { ...formErrors };
        validateField(name, value, errors);
        setFormErrors(errors);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let errors = { ...formErrors };
        validateField(name, value, errors);
        setFormErrors(errors);
    };

    const handleSubmit = async () => {
        let errors = {};
        Object.keys(formData).forEach((field) => {
            validateField(field, formData[field], errors);
        });
        console.log("formData:", formData)
        console.log("Object.keys(errors).length:", Object.keys(errors))

        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            const requestData = {
                apiKeyId: selectedapikey ? selectedapikey.apiKeyId : 0,
                apiKeyName: formData.apiKey,
                adminApiEndPoint: formData.adminApiEndpoint,
                userApiEndPoint: formData.userApiEndpoint,
                isActive: true,
                createdDate: new Date().toISOString(),
                apiKeyType: 0
            };
            console.log("Entered into apiblock")
            const { statusCode, message, error } = await postApi(
                ADD_UPDATE_APIKEY,
                requestData
            );
            if (statusCode === 200) {
                ToastHelper.success(message);
                setIsLoading(false);
                setShowApiKeyModal(false);
                setRecallApi((prevState) => !prevState);
            } else {
                ToastHelper.error(error);
                setIsLoading(false);
            }
            // if (onFormSuccess) {
            //     onFormSuccess();
            // }
        } else {
            setFormErrors(errors);
        }
    };

    const fields = [
        // { label: "Project No", name: "projectNo", placeholder: "Enter Project No", disabled: false, onInput: (e) => (e.target.value = e.target.value.replace(/\D/g, "")) },
        { label: "Enter API Key", name: "apiKey", placeholder: "Enter API Key" },
        { label: "Enter Admin API Endpoint", name: "adminApiEndpoint", placeholder: "Enter Admin API Endpoint" },
        { label: "Enter User API Endpoint", name: "userApiEndpoint", placeholder: "Enter User API Endpoint" },

    ];



    return (
        <div className="customer">


            <CustomModal
                isOpen={showApiKeyModal}
                onClose={() => {
                    setShowApiKeyModal(false);
                    setFormErrors({})
                }}
                title={selectedapikey ? "Update API Key" : "Add API Key"}
                showPrimaryButton={true}
                showSecondaryButton={true}
                onPrimaryClick={() => {
                    handleSubmit();
                }}
                onSecondaryClick={() => { setShowApiKeyModal(false) }}
                primaryLabel={selectedapikey ? "Update" : "Add"}
                secondaryLabel="Cancel"
            >
                {fields.map(({ label, name, placeholder, disabled, maxLength, onInput, hidestar }) => (
                    <div className="form-field" key={name}>
                        <label htmlFor={name} style={{ fontWeight: "bold" }}>
                            {label}
                            {!hidestar && <span className="required" style={{ color: "red" }}>*</span>}
                        </label>
                        <Input
                            id={name}
                            name={name}
                            value={formData[name]}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                            placeholder={placeholder}
                            disabled={disabled}
                            maxLength={maxLength}
                            onInput={onInput}
                            size="large"
                            style={{ margin: "5px 0 5px" }}
                        />
                        {formErrors[name] && <p className="error" style={{ margin: "0 0 10px 0", color: "red" }}>{formErrors[name]}</p>}
                    </div>
                ))}


            </CustomModal>

            <ApiKeysTable
                onAddClick={handleAddClick}
                onEditClick={handleEditClick}
                recallApi={recallApi}
            />

        </div>
    );
};

export default ApiKeys;
