import React, { useState } from "react";
import { Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import "./ResetPassword.scss";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { postApi } from "../../Utils/apiService";
import { ADMIN_RESET_PASSWORD } from "../../Utils/apiPath";
import ToastHelper from "../../Services/ToastHelper";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({ password: "", confirmPassword: "" });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailFromUrl = searchParams.get("email");

  const validateField = (name, value, errors, formData) => {
    if (name === "password") {
      if (!value.trim()) {
        errors.password = "Password cannot be empty";
      } else if (value.length < 6) {
        errors.password = "Password must be at least 6 characters long";
      } else {
        delete errors.password;
      }
    } else if (name === "confirmPassword") {
      if (!value.trim()) {
        errors.confirmPassword = "Please confirm your password";
      } else if (value !== formData.password) {
        errors.confirmPassword = "Passwords do not match";
      } else {
        delete errors.confirmPassword;
      }
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    let updatedErrors = { ...errors };
    validateField(name, value, updatedErrors, formData);
    setErrors(updatedErrors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    let updatedErrors = { ...errors };
    validateField(name, value, updatedErrors, formData);
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedErrors = { ...errors };
    validateField("password", formData.password, updatedErrors, formData);
    validateField(
      "confirmPassword",
      formData.confirmPassword,
      updatedErrors,
      formData
    );

    setErrors(updatedErrors);

    if (!updatedErrors.password && !updatedErrors.confirmPassword) {
      setIsLoading(true);
      const payload = { password: formData.password, email: emailFromUrl };

      try {
        const { statusCode, message, error } = await postApi(
          ADMIN_RESET_PASSWORD,
          payload
        );

        if (statusCode === 200) {
          ToastHelper.success(message);
          navigate("/");
        } else {
          ToastHelper.error(error);
        }
      } catch (err) {
        console.error("API Error:", err.message);
        ToastHelper.error("An error occurred while resetting the password.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-box">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}>
          <div className="input-wrapper">
            <Input.Password
              className={`input-field ${errors.password ? "error" : ""}`}
              size="large"
              placeholder="Enter new password"
              prefix={<LockOutlined />}
              name="password"
              value={formData.password}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </div>

          <div className="input-wrapper">
            <Input.Password
              className={`input-field ${errors.confirmPassword ? "error" : ""}`}
              size="large"
              placeholder="Confirm new password"
              prefix={<LockOutlined />}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {errors.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
          </div>

          <CustomButton
            variant="secondary"
            block
            className="reset-password-btn"
            onClick={handleSubmit}
            style={{ border: "none", width: "60%", backgroundColor: "#ecd2ec" }}
          >
            {isLoading ? "Loading..." : "Reset Password"}
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
