import React, { useEffect, useState } from "react";
import { Input } from "antd";
import "./CustomerAddForm.scss";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { ADD_UPDATE_USER } from "../../../Utils/apiPath";
import { postApi } from "../../../Utils/apiService";
import ToastHelper from "../../../Services/ToastHelper";
import { BackArrow } from "../../../Assets/Icons/Icons";

const CustomerAddForm = ({ onFormSuccess, customerData }) => {
  const [formData, setFormData] = useState({
    projectNo: "",
    customerName: "",
    phone: "",
    email: "",
    companyName: "",
    faProject: "",
    linkedinEngage: "",
    paidSearch: "",
    websiteTraffic: "",
    emailTraffic: "",
  });

  useEffect(() => {
    if (customerData) {
      setFormData({
        projectNo: customerData.projectId || "",
        customerName: customerData.name || "",
        phone: customerData.phoneNumber || "",
        email: customerData.email || "",
        companyName: customerData.companyName || "",
        faProject: customerData.projectName || "",
        linkedinEngage: customerData.linkedInProjectName || "",
        paidSearch: customerData.paidSearchProjectName || "",
        websiteTraffic: customerData.websiteTraffic || "",
        emailTraffic: customerData.emailCampaignProjectName || "",
      });
    }
  }, [customerData]);

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name, value, errors) => {
    const fieldValidations = {
      projectNo: { required: "Project No is required." },
      customerName: { required: "Customer Name is required." },
      phone: {
        required: "Phone number is required.",
        pattern: { regex: /^\d{10}$/, message: "Phone number must be 10 digits." },
      },
      email: {
        required: "Email address is required.",
        pattern: { regex: /\S+@\S+\.\S+/, message: "Email address is invalid." },
      },
      companyName: { required: "Company Name is required." },
      faProject: { required: "FA Project is required." },
      // linkedinEngage: { required: "LinkedIn Engage is required." },
      // paidSearch: { required: "Paid Search is required." },
    };

    const validation = fieldValidations[name];
    if (validation) {
      if (!value.trim()) {
        errors[name] = validation.required;
      } else if (validation.pattern && !validation.pattern.regex.test(value)) {
        errors[name] = validation.pattern.message;
      } else {
        delete errors[name];
      }
    }
  };


  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    let errors = { ...formErrors };
    validateField(name, value, errors);
    setFormErrors(errors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };
    validateField(name, value, errors);
    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    Object.keys(formData).forEach((field) => {
      validateField(field, formData[field], errors);
    });
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const requestData = {
        id: customerData?.id || 0,
        name: formData.customerName,
        email: formData.email,
        companyName: formData.companyName,
        projectName: formData.faProject,
        paidSearchProjectName: formData.paidSearch,
        linkedInProjectName: formData.linkedinEngage,
        projectId: formData.projectNo,
        phoneNumber: formData.phone,
        websiteTraffic: formData.websiteTraffic,
        emailCampaignProjectName: formData.emailTraffic,
        isActive: true,
        refreshToken: "string",
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
      };
      const { statusCode, message, error } = await postApi(
        ADD_UPDATE_USER,
        requestData
      );
      if (statusCode === 200) {
        ToastHelper.success(message);
        setIsLoading(false);
      } else {
        ToastHelper.error(error);
        setIsLoading(false);
      }
      if (onFormSuccess) {
        onFormSuccess();
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleBack = () => {
    if (onFormSuccess) {
      onFormSuccess();
    }
  };

  const fields = [
    { label: "Project No", name: "projectNo", placeholder: "Enter Project No", disabled: !!customerData, onInput: (e) => (e.target.value = e.target.value.replace(/\D/g, "")) },
    { label: "Company Name", name: "companyName", placeholder: "Enter Company Name"},
    { label: "Customer Name", name: "customerName", placeholder: "Enter Customer Name" },
    { label: "Email", name: "email", placeholder: "Enter Email" },
    { label: "Phone Number", name: "phone", placeholder: "Enter Phone Number", maxLength: 10, onInput: (e) => (e.target.value = e.target.value.replace(/\D/g, "")) },
    { label: "FA Project", name: "faProject", placeholder: "Enter Project Name" },
    { label: "LinkedIn Engage", name: "linkedinEngage", placeholder: "Enter LinkedIn Engage", hidestar: true },
    { label: "Paid Search", name: "paidSearch", placeholder: "Enter Paid Search", hidestar: true },
    { label: "Website Traffic", name: "websiteTraffic", placeholder: "Enter Website Traffic", hidestar: true },
    { label: "Email Traffic", name: "emailTraffic", placeholder: "Enter Email Traffic", hidestar: true }
  ];

  return (
    <div className="customeradd">
      <div className="customeradd_header">
        <div className="customeradd_header_back" onClick={handleBack}>
          <BackArrow />
        </div>
        <h2>{customerData ? "Edit Customer" : "Add Customer"}</h2>
      </div>
      <form className="customer-add-form" onSubmit={handleSubmit}>

        {fields.map(({ label, name, placeholder, disabled, maxLength, onInput, hidestar }) => (
          <div className="form-field" key={name}>
            <label htmlFor={name}>
              {label}
              {!hidestar && <span className="required">*</span>}
            </label>
            <Input
              id={name}
              name={name}
              value={formData[name]}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              onInput={onInput}
            />
            {formErrors[name] && <p className="error">{formErrors[name]}</p>}
          </div>
        ))}

        <div className="form-actions">
          <CustomButton
            variant="primary"
            onClick={handleSubmit}
            loading={isLoading}
            className="submit-button"
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default CustomerAddForm;
