import React, { useEffect, useRef, useState } from "react";
import "./AdminLayout.scss";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  DashboardOutlined,
  SoundOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import CustomButton from "../CustomButton/CustomButton";
import logo from "../../Assets/Images/whiteLogo.png";
import logoimg from "../../Assets/Images/logo-white-cropped-img.svg";
import user from "../../Assets/Images/avatar.png";

const { Header, Sider, Content } = Layout;

const items = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    key: "2",
    icon: <UserOutlined />,
    label: "Customer Management",
    path: "/customermanagement",
  },
  {
    key: "3",
    icon: <SoundOutlined />,
    label: "Campaign Management",
    path: "/campaignmanagement",
  },
  {
    key: "4",
    icon: <KeyOutlined />,
    label: "Manage API Keys",
    path: "/apikeys",
  },
  //   {
  //     key: "4",
  //     icon: <FileTextOutlined />,
  //     label: "Assign Campaign",
  //     path: "/assigncampaign",
  //   },
  //   {
  //     key: "5",
  //     icon: <FileTextOutlined />,
  //     label: "Campaign Report",
  //     path: "/campaignreport",
  //   },
];

const AdminLayout = ({ children, sidebarWidth = "16%" }) => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);


  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

  // Function to toggle sidebar on mobile screens
  const toggleMobileNav = () => {
    setIsMobileNavVisible(!isMobileNavVisible);
  };

  console.log(collapsed, "collapsed")
  const getSelectedKey = () => {
    const currentPath = location.pathname;
    const currentItem = items.find((item) => item.path === currentPath);
    return currentItem ? currentItem.key : "1";
  };

  const [selectedKey, setSelectedKey] = useState(getSelectedKey);

  useEffect(() => {
    setSelectedKey(getSelectedKey());
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("collapsed", collapsed);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };



  const handleOpenDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAdminLogout = async () => {
    localStorage.clear();
    navigate("/");
  };

  const handleCancel = () => {
    setDropdownVisible(false);
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  }

  const handleMenuClick = (key, path) => {
    setSelectedKey(key);
    navigate(path);
    if (window.innerWidth < 768) {
      setIsMobileNavVisible(false); // Close the sidebar on mobile after clicking a menu item
    }
  };

  return (
    <div className="adminlayout">
      <Layout style={{ minHeight: "100vh", background: "#fceaff" }}>
        {/* {!isMobileNavVisible && (
          <RightCircleOutlined
            className="mobile-nav-toggle"
            style={{ fontSize: "24px", position: "absolute", top: "20px", left: "20px", zIndex: 1000 }}
            onClick={toggleMobileNav}
          />
        )} */}
        <Sider
          // breakpoint="md"
          // collapsedWidth="0"
          width={collapsed ? 80 : 250}
          className={`sidebar ${collapsed ? "collapsed" : ""} ${isMobileNavVisible ? "visible" : "hidden"}`}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          trigger={
            <div className="custom-trigger">
              {collapsed ? (
                <RightCircleOutlined style={{ fontSize: "24px" }} />
              ) : (
                <LeftCircleOutlined style={{ fontSize: "24px" }} />
              )}
            </div>
          }
        >

          <div className="sidebar_logo">
            <img
              src={collapsed ? logoimg : logo}
              alt="logo"
              className={collapsed ? "collapsed" : ""}
              onClick={handleDashboard}
            />
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            items={items.map(({ key, icon, label, path }) => ({
              key,
              icon,
              label,
              onClick: () => handleMenuClick(key, path),
            }))}
          />
        </Sider>
        <Layout style={{ background: "#fceaff" }}>
          <Header
            style={{ background: "#f8dffc", padding: 0 }}
            className="header"
          >
            <div className="header_sec">
              <div className="header_sec_logout">
                <img src={user} alt="user" onClick={handleOpenDropdown} />
                {dropdownVisible && (
                  <div className="custom-dropdown" ref={dropdownRef}>
                    <h3>Are you sure you want to Logout?</h3>
                    <div className="custom-dropdown_buttons">
                      <CustomButton
                        variant="secondary"
                        size="medium"
                        onClick={handleCancel}
                      >
                        No
                      </CustomButton>

                      <CustomButton
                        variant="primary"
                        size="medium"
                        onClick={handleAdminLogout}
                      >
                        Yes
                      </CustomButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Header>
          <Content style={{ margin: "16px" }}>
            <div className="center">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminLayout;
