import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import "./Dashboard.scss";
import { GET_DASHBOARD_DATA } from "../../Utils/apiPath";
import { getApi } from "../../Utils/apiService";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  const fetchData = async () => {
    const { statusCode, data } = await getApi(GET_DASHBOARD_DATA);
    if (statusCode === 200) {
      setDashboardData(data);
    } else {
      setDashboardData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <Row gutter={[16, 16]} justify="space-between">
        {/* Each card now takes up 100% width on extra-small screens, 50% on small screens (tablets), and 33.33% on medium and larger screens */}
        <Col xs={24} sm={12} md={8}>
          <Card
            title="Total Number of Campaigns"
            bordered={false}
            className="card-total-campaigns"
          >
            {dashboardData.totalCampaigns}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card
            title="Total Number of Customers"
            bordered={false}
            className="card-total-customers"
          >
            {dashboardData.totalCustomerCount}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card
            title="Active/Inactive Customer Summary"
            bordered={false}
            className="card-customer-summary"
          >
            Active: {dashboardData.activeUserCount} | Inactive:{" "}
            {dashboardData.inactiveUserCount}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24} sm={12} md={12}>
          <Card
            title="No. of Assigned Campaigns"
            bordered={false}
            className="card-assigned-campaigns"
          >
            {dashboardData.assignedCampaignCount}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12}>
          <Card
            title="No. of Unassigned Campaigns"
            bordered={false}
            className="card-unassigned-campaigns"
          >
            {dashboardData.unAssignedCampaignCount}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
