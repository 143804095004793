import React, { useCallback, useEffect, useState } from "react";
import "./CampaignTable.scss";
import { debounce } from "lodash";
import { Table, Tooltip, Dropdown, Spin, Flex, Select, Space, Divider, Button, theme } from "antd";
import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getApi, postApi } from "../../../Utils/apiService";
import { GET_ALL_CAMPAIGNS, GET_APIENDPOINTS_DROPDOWN, GET_APIKEYS_DROPDOWN } from "../../../Utils/apiPath";
import useTableHeight from "../../../Services/TableHeight";
import ToastHelper from "../../../Services/ToastHelper";
import CustomButton from "../../../Components/CustomButton/CustomButton";

const CampaignTable = ({ onAddClick, onReportClick }) => {

  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const tableHeight = useTableHeight();
  const [apiKeyOptions, setApiKeyOptions] = useState([]);
  const [apiEndpointOptions, setApiEndpointOptions] = useState([]);
  const [initialApiEndpoint, setInitialApiEndpoint] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState([]);
  const [selectedApiEndpoint, setSelectedApiEndpoint] = useState();
  const [isFirstApiCall, setIsFirstApiCall] = useState(true);

  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchData(1, pageSize, query);
    }, 500),
    [pageSize, selectedApiKey, selectedApiEndpoint]
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const menuItems = (record) => [
    {
      key: "edit",
      label: <a onClick={() => onReportClick(record)}>Update Reports</a>,
    },
  ];

  const columns = [
    {
      title: "SL",
      // dataIndex: "r",
      key: "serialNo",
      width: 30, // fixed width in px
      render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    {
      title: "Campaign ID",
      dataIndex: "campaignID",
      key: "campaignID",
      width: 100,
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      width: 150,
    },
    {
      title: "Status (Assigned/ Unassigned)",
      dataIndex: "isAssigned",
      key: "status",
      width: 120,
      render: (isAssigned) => (isAssigned ? "Assigned" : "UnAssigned"),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 100,
    },
    {
      title: "Assigned Date",
      dataIndex: "assignedDate",
      key: "assignedDate",
      width: 100,
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Project No",
      dataIndex: "projectNo",
      key: "projectNo",
      width: 80,
    },
    {
      title: "Action",
      key: "operation",
      width: 30,
      fixed: "right",
      render: (_, record) => (
        <Dropdown menu={{ items: menuItems(record) }} trigger={["click"]}>
          <Tooltip title="More actions">
            <MoreOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
          </Tooltip>
        </Dropdown>
      ),
    },
  ];


  const fetchData = async (pageIndex, pageSize, searchQuery = "") => {
    setTableLoading(true);

    const requestBody = {
      pageIndex: pageIndex - 1,
      pageSize,
      searchString: searchQuery,
      apiKey: selectedApiKey.apiKeyName,
      // apiEndPoint: initialApiEndpoint ? initialApiEndpoint.apiEndpointName : selectedApiEndpoint.apiEndpointName,
      apiEndPoint: selectedApiEndpoint.apiEndpointName,
      apiKeyId: selectedApiKey.apiKeyId
    };
    const response = await postApi(GET_ALL_CAMPAIGNS, requestBody);
    const { statusCode, error, data } = response;
    if (statusCode === 200) {
      const transformedData = data.map((campaign) => ({
        ...campaign,
        customerName: campaign.assignedUsers[0]?.userName || "",
        projectNo: campaign.assignedUsers[0]?.projectNo || "",
        assignedDate: campaign.assignedUsers[0]?.assignedDate || "",
      }));
      setData(transformedData);
      setTotalCount(response.totalCount);
      setTableLoading(false);

    } else {
      // ToastHelper.error(error);
      setTotalCount(response.totalCount);
      setTableLoading(false);
      setData([]);
    }
  };
  console.log("selectedApiKey", selectedApiKey);
  console.log("selectedApiEndpoint:", selectedApiEndpoint);
  console.log("initialApiEndpoint:", initialApiEndpoint);
  console.log("totalCount:", totalCount);


  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  const getapikeysdropdown = async () => {
    setLoading(true);
    const response = await getApi(GET_APIKEYS_DROPDOWN);
    const { statusCode, error, data } = response;

    if (statusCode === 200) {
      setApiKeyOptions(data);
      const selectedKey = { apiKeyId: data[0]?.apiKeyId, apiKeyName: data[0]?.apiKeyName };
      setSelectedApiKey(selectedKey);
      if (selectedKey.apiKeyId) {
        await getapiendpointsdropdown(selectedKey.apiKeyId);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }


  const getapiendpointsdropdown = async (apiKeyId) => {
    setLoading(true);

    const { statusCode, error, data } = await getApi(`${GET_APIENDPOINTS_DROPDOWN}?ApiKeyId=${apiKeyId}`);

    if (statusCode === 200) {
      setApiEndpointOptions(data);
      if (isFirstApiCall) {
        const initialendpoint = { apiEndpointId: data[0]?.apiKeyId, apiEndpointName: data[0]?.apiEndPoint };
        setInitialApiEndpoint(initialendpoint);
        setSelectedApiEndpoint(initialendpoint);
        setIsFirstApiCall(false);
      }
      setSelectedApiEndpoint({ apiEndpointId: data[0]?.apiKeyId, apiEndpointName: data[0]?.apiEndPoint });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };


  useEffect(() => {
    getapikeysdropdown();
  }, [])

  useEffect(() => {
    if (selectedApiKey && selectedApiEndpoint) {
      fetchData(pageIndex, pageSize);
    }
  }, [pageIndex, pageSize, initialApiEndpoint]);


  const onChangeApiKey = (value, option) => {
    // setInitialApiEndpoint({ apiEndpointId: '', apiEndpointName: '' });
    setSelectedApiEndpoint({ apiEndpointId: '', apiEndpointName: '' });
    const selectedKey = { apiKeyId: value, apiKeyName: option.label }
    setSelectedApiKey(selectedKey);
    if (selectedKey.apiKeyId) {
      getapiendpointsdropdown(selectedKey.apiKeyId);
    }
  };


  const onChangeApiEndpoint = (value, option) => {
    const selectedendpoint = { apiEndpointId: value, apiEndpointName: option.label };
    setSelectedApiEndpoint(selectedendpoint);
  };

  const handleApply = () => {
    fetchData(pageIndex, pageSize);
  }

  return (
    <div className="campaigntable">
      <div className="campaigntable_header">
        <div className="campaigntable_header_left">
          <h2>Campaign Management</h2>
        </div>
        <div className="campaigntable_header_right">

          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
          <div className="campaigntable_header_right_filter">
            <Dropdown
              trigger={["hover", "click"]}
              dropdownRender={(menu) => (
                <div className="campaigntable_header_right_filterdropdown" style={contentStyle}>
                  <div style={{ padding: '8px' }}>
                    <label style={{ marginBottom: '4px', display: 'block' }}>Select API Key</label>
                    <Select
                      defaultValue={selectedApiKey.apiKeyName}
                      placeholder="Select API Key"
                      style={{ marginBottom: 8, width: '100%' }}
                      onChange={onChangeApiKey}
                      options={apiKeyOptions.map((key) => ({
                        value: key.apiKeyId,
                        label: key.apiKeyName,
                      }))}
                    />

                  </div>
                  <div style={{ padding: '8px' }}>
                    <label style={{ marginBottom: '4px', display: 'block' }}>Select API Endpoint</label>

                    <Select
                      value={selectedApiEndpoint.apiEndpointName}

                      placeholder="Select API End Point"
                      style={{ width: '100%' }}
                      onChange={onChangeApiEndpoint}
                      options={apiEndpointOptions.map((endpoint) => ({
                        value: endpoint.apiKeyId,
                        label: endpoint.apiEndPoint,
                      }))}
                    />
                  </div>
                  <Divider style={{ margin: '8px 0' }} />
                  <Space
                    style={{
                      padding: 8,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <CustomButton variant="primary" size="medium" loading={loading} onClick={handleApply}>
                      Apply
                    </CustomButton>
                  </Space>
                </div>
              )}
            >
              <Button>
                <Space>
                  FILTER
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>

          </div>


        </div>
      </div>

      {tableLoading ? (
        <Flex align="center" justify="center" gap="middle">
          <Spin />
        </Flex>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: pageIndex,
            pageSize,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: getPageSizeOptions(),
            onChange: (page, size) => {
              setPageIndex(page);
              setPageSize(size);
            },
          }}
          scroll={{
            x: 1500,
            y: tableHeight || 300,
          }}
          rowKey="campaignID"
        />
      )}
    </div>
  );
};

export default CampaignTable;
