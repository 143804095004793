import React, { useState } from "react";
import CustomerTable from "./CustomerTable/CustomerTable";
import CustomerAddForm from "./CustomerAddForm/CustomerAddForm";

const CustomerManagement = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleFormSuccess = () => {
    setShowAddForm(false);
    setSelectedCustomer(null);
  };

  const handleEditClick = (customer) => {
    setSelectedCustomer(customer);
    setShowAddForm(true);
  };

  return (
    <div className="customer">
      {showAddForm ? (
        <CustomerAddForm
          onFormSuccess={handleFormSuccess}
          customerData={selectedCustomer}
        />
      ) : (
        <CustomerTable
          onAddClick={() => {
            setSelectedCustomer(null);
            setShowAddForm(true);
          }}
          onEditClick={handleEditClick}
        />
      )}
    </div>
  );
};

export default CustomerManagement;
