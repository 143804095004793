import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import AdminLayout from "../Components/AdminLayout/AdminLayout";
import CustomerManagement from "../Pages/CustomerManagement/CustomerManagement";
import { CampaignManagement } from "../Pages/CampaignManagement/CampaignManagement";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import AssignCampaign from "../Pages/AssignCampaign/AssignCampaign";
import CampaignReport from "../Pages/CampaignManagement/CampaignReport/CampaignReport";
import ApiKeys from "../Pages/ApiKeys/ApiKeys";

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return <Navigate to="/" />;
  }

  return children;
};

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <Dashboard />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/customermanagement"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CustomerManagement />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaignmanagement"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CampaignManagement />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/assigncampaign"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <AssignCampaign />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaignreport"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CampaignReport />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
         <Route
          path="/apikeys"
          element={
            <ProtectedRoute>
              <AdminLayout>
                <ApiKeys />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
