import React, { useEffect, useState } from "react";
import { Input } from "antd";
import "./CampaignReport.scss";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { postApi } from "../../../Utils/apiService";
import ToastHelper from "../../../Services/ToastHelper";
import {
  ADD_BROWSER_STATS,
  ADD_DEVICE_STATS,
  GET_BROWSERSTATS_BY_CAMPAIGNID,
  GET_DEVICESTATS_BY_CAMPAIGNID,
} from "../../../Utils/apiPath";
import { BackArrow } from "../../../Assets/Icons/Icons";

const CampaignReport = ({ onBackClick, selectedCampaign }) => {
  const [formData, setFormData] = useState({
    chrome: "",
    safari: "",
    mozilla: "",
    edge: "",
    opera: "",
    desktopOpenCount: "",
    desktopClickCount: "",
    tabletOpenCount: "",
    tabletClickCount: "",
    mobileOpenCount: "",
    mobileClickCount: "",
    webmailOpenCount: "",
    webmailClickCount: "",
    otherOpenCount: "",
    otherClickCount: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAllStats = async () => {
    setIsLoading(true);
    try {
      const assignedUser =
        selectedCampaign.assignedUsers.length > 0
          ? selectedCampaign.assignedUsers[0]
          : null;

      const payload = {
        userId: assignedUser ? assignedUser.userId : null,
        campaignId: selectedCampaign.id,
        fromDate: "2024-09-20T04:39:27.848Z",
        toDate: "2024-09-20T04:39:27.848Z",
      };

      if (!payload.userId) {
        throw new Error("No assigned user found.");
      }

      const browserStatsResponse = await postApi(
        GET_BROWSERSTATS_BY_CAMPAIGNID,
        payload
      );

      if (browserStatsResponse.statusCode === 200) {
        const browserData = browserStatsResponse.data;
        setFormData((prevData) => ({
          ...prevData,
          chrome: browserData.chromePercentage || 0,
          safari: browserData.safariPercentage || 0,
          mozilla: browserData.mozillaPercentage || 0,
          edge: browserData.edgePercentage || 0,
          opera: browserData.operaPercentage || 0,
        }));
        // ToastHelper.success(browserStatsResponse.message);
        // setFormErrors({});
      } else {
        // ToastHelper.error(browserStatsResponse.error);
      }

      const deviceStatsResponse = await postApi(
        GET_DEVICESTATS_BY_CAMPAIGNID,
        payload
      );

      if (deviceStatsResponse.statusCode === 200) {
        const deviceData = deviceStatsResponse.data;
        setFormData((prevData) => ({
          ...prevData,
          desktopOpenCount: deviceData[0]?.openCount || 0,
          desktopClickCount: deviceData[0]?.clickCount || 0,
          tabletOpenCount: deviceData[1]?.openCount || 0,
          tabletClickCount: deviceData[1]?.clickCount || 0,
          mobileOpenCount: deviceData[2]?.openCount || 0,
          mobileClickCount: deviceData[2]?.clickCount || 0,
          webmailOpenCount: deviceData[3]?.openCount || 0,
          webmailClickCount: deviceData[3]?.clickCount || 0,
          otherOpenCount: deviceData[4]?.openCount || 0,
          otherClickCount: deviceData[4]?.clickCount || 0,
        }));
        // ToastHelper.success(deviceStatsResponse.message);
        // setFormErrors({});
      } else {
        // ToastHelper.error(deviceStatsResponse.error);
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
      ToastHelper.error("Failed to fetch stats.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllStats();
  }, []);

  const validateField = (name, value, errors) => {
    if (value === null || value === undefined || value === "") {
      errors[name] = `${name} is required.`;
    }
    else if (isNaN(Number(value))) {
      errors[name] = `${name} must be a valid number.`;
    }
    else {
      delete errors[name];
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    let errors = { ...formErrors };
    validateField(name, value, errors);
    setFormErrors(errors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };
    validateField(name, value, errors);
    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};

    Object.keys(formData).forEach((field) => {
      validateField(field, formData[field], errors);
    });

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      try {
        const browserPayload = {
          id: 0,
          chromeCount: String(formData.chrome),
          safari: String(formData.safari),
          mozillaCount: String(formData.mozilla),
          edge: String(formData.edge),
          opera: String(formData.opera),
          campaignId: selectedCampaign.id,
          userId: selectedCampaign.assignedUsers[0]?.userId,
        };

        const {
          statusCode: browserStatus,
          message: browserMessage,
          error: browserError,
        } = await postApi(ADD_BROWSER_STATS, browserPayload);

        if (browserStatus === 200) {
          ToastHelper.success(browserMessage);
        } else {
          ToastHelper.error(browserError);
        }

        const devicePayload = [
          {
            id: 0,
            openCount: String(formData.desktopOpenCount),
            clickCount: String(formData.desktopClickCount),
            deviceId: 1, // Desktop
            campaignId: selectedCampaign.id,
            fromDate: "2024-09-20T05:27:24.853Z",
            toDate: "2024-09-20T05:27:24.853Z",
          },
          {
            id: 0,
            openCount: String(formData.tabletOpenCount),
            clickCount: String(formData.tabletClickCount),
            deviceId: 2,
            campaignId: selectedCampaign.id,
            fromDate: "2024-09-20T05:27:24.853Z",
            toDate: "2024-09-20T05:27:24.853Z",
          },
          {
            id: 0,
            openCount: String(formData.mobileOpenCount),
            clickCount: String(formData.mobileClickCount),
            deviceId: 3,
            campaignId: selectedCampaign.id,
            fromDate: "2024-09-20T05:27:24.853Z",
            toDate: "2024-09-20T05:27:24.853Z",
          },
          {
            id: 0,
            openCount: String(formData.webmailOpenCount),
            clickCount: String(formData.webmailClickCount),
            deviceId: 4,
            campaignId: selectedCampaign.id,
            fromDate: "2024-09-20T05:27:24.853Z",
            toDate: "2024-09-20T05:27:24.853Z",
          },
          {
            id: 0,
            openCount: String(formData.otherOpenCount),
            clickCount: String(formData.otherClickCount),
            deviceId: 5,
            campaignId: selectedCampaign.id,
            fromDate: "2024-09-20T05:27:24.853Z",
            toDate: "2024-09-20T05:27:24.853Z",
          },
        ];

        const {
          statusCode: deviceStatus,
          message: deviceMessage,
          error: deviceError,
        } = await postApi(ADD_DEVICE_STATS, devicePayload, {
          params: { userId: selectedCampaign.assignedUsers[0]?.userId },
        });

        if (deviceStatus === 200) {
          ToastHelper.success(deviceMessage);
        } else {
          ToastHelper.error(deviceError);
        }
        onBackClick();
      } catch (error) {
        console.error("Error submitting form:", error);
        ToastHelper.error("Failed to submit stats.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormErrors(errors);
      console.log("Form validation failed:", errors);
    }
  };

  const browserFields = ["chrome", "safari", "mozilla", "edge", "opera"];
  const deviceFields = [
    "desktopOpenCount",
    "desktopClickCount",
    "tabletOpenCount",
    "tabletClickCount",
    "mobileOpenCount",
    "mobileClickCount",
    "webmailOpenCount",
    "webmailClickCount",
    "otherOpenCount",
    "otherClickCount",
  ];

  return (
    <div className="campaign-report">
      <div className="campaign-report_header" >
        <div className="customeradd_header_back" onClick={() => onBackClick()}>
          <BackArrow />
        </div>
        <span className="text-container">Campaign Name:&nbsp;{selectedCampaign.campaignName}</span>
      </div>
      <form className="campaign-report-form" onSubmit={handleSubmit}>
        <h2>Browser Stats</h2>
        {browserFields.map((field) => (
          <div key={field} className="form-field">
            <label htmlFor={field}>
              {field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <Input
              id={field}
              name={field}
              type="number"
              placeholder={`Enter ${field} stats`}
              value={formData[field] || ""}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {formErrors[field] && (
              <span className="error">{formErrors[field]}</span>
            )}
          </div>
        ))}

        <h2>Device Stats</h2>
        {deviceFields.map((field) => (
          <div key={field} className="form-field">
            <label htmlFor={field}>
              {field
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())}
            </label>
            <Input
              id={field}
              name={field}
              placeholder={`Enter ${field}`}
              value={formData[field] || ""}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {formErrors[field] && (
              <span className="error">{formErrors[field]}</span>
            )}
          </div>
        ))}

        <div className="form-actions">
          <CustomButton
            onClick={handleSubmit}
            variant="primary"
            loading={isLoading}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default CampaignReport;
