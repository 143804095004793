import React, { useState } from 'react';
import { Input } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import './ForgotPassword.scss';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { postApi } from '../../Utils/apiService';
import { ADMIN_FORGOT_PASSWORD } from '../../Utils/apiPath';
import ToastHelper from '../../Services/ToastHelper';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name, value, errors) => {
    if (name === 'email') {
      if (!value.trim()) {
        errors.email = 'Email cannot be empty';
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        errors.email = 'Invalid email format';
      } else {
        delete errors.email;
      }
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    let updatedErrors = { ...errors };
    validateField(name, value, updatedErrors);
    setErrors(updatedErrors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    let updatedErrors = { ...errors };
    validateField(name, value, updatedErrors);
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedErrors = { ...errors };
    validateField('email', email, updatedErrors);
    setErrors(updatedErrors);

    if (!updatedErrors.email) {
      setIsLoading(true);
      const payload = { email };

      try {
        const { statusCode, message, error } = await postApi(
          ADMIN_FORGOT_PASSWORD,
          payload
        );

        if (statusCode === 200) {
          // ToastHelper.success(message);
           ToastHelper.success("A Link has been sent to your registered email address to reset your password.");
        } else {
          ToastHelper.error(error);
        }
      } catch (err) {
        console.error('API Error:', err.message);
        ToastHelper.error("An error occurred while sending the reset link.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}>
          <div className="input-wrapper">
            <Input
              className={`input-field ${errors.email ? 'error' : ''}`}
              size="large"
              placeholder="Enter your registered email"
              prefix={<MailOutlined />}
              name="email"
              value={email}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>
          <CustomButton
            variant="secondary"
            block
            className="forgot-password-btn"
            style={{ border: "none", width: "60%", backgroundColor: "#ecd2ec" }}
            onClick={handleSubmit}
          >
            {isLoading ? "Loading..." : "Submit"}
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
