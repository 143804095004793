// Account
export const ADMIN_LOGIN_DETAILS = "Account/AdminLogin";
export const ADMIN_FORGOT_PASSWORD = "Account/ForgotPassword";
export const ADMIN_RESET_PASSWORD = "Account/ResetPassword";

//Customer
export const ADD_UPDATE_USER = "Account/AddOrUpdateUser";
export const GET_ACTIVE_USERS = "Account/GetActiveCustomers";

//Dashboard
export const ADD_BROWSER_STATS = "DashBoard/AddOrUpdateBrowserStats";
export const ADD_DEVICE_STATS = "DashBoard/AddDeviceStats";
export const GET_DASHBOARD_DATA = "Campaigns/GetDashboardStats";

//Campaigns
export const GET_ALL_CAMPAIGNS = "Campaigns/GetAllCampaigns";
export const GET_UNASSIGNED_CAMPAIGNS = "Campaigns/GetUnAssignedCampaigns";
export const ADD_CAMPAIGNS_FOR_USER = "Campaigns/AddCampaignsToUser";
export const GET_ASSIGNED_CAMPAIGNS = "Campaigns/GetAllCampaignByUserId";
export const REMOVE_CAMPAIGNS_FOR_USER = "Campaigns/UnassignCampaignsFromUser";
export const INACTIVE_USER = "Account/InActiveUser";
export const GET_ALL_APIKEYS = "Campaigns/GetApiKey";
export const ADD_UPDATE_APIKEY = "Campaigns/AddOrUpdateManageApiKey";
export const DEACTIVATE_APIKEY = "Campaigns/DeactivateApiKey";
export const GET_APIKEYS_DROPDOWN = "Campaigns/GetApiKeyForAdmin";
export const GET_APIENDPOINTS_DROPDOWN = "Campaigns/GetApiEndPointForAdmin";


//UserAdmin
export const GET_BROWSERSTATS_BY_CAMPAIGNID = "Campaigns/GetAdminBrowserStats";
export const GET_DEVICESTATS_BY_CAMPAIGNID = "Campaigns/GetDeviceStatsByCampaignId";
