import React, { useState } from 'react';
import "./CampaignManagement.scss";
import CampaignTable from './CampaignTable/CampaignTable';
import CampaignReport from './CampaignReport/CampaignReport';

export const CampaignManagement = () => {
  const [isReportVisible, setIsReportVisible] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleReport = (record) => {
  setSelectedCampaign(record);
  setIsReportVisible(true);
};

  const handleBackToTable = () => {
    setIsReportVisible(false);
  };

  return (
    <div className='campaign'>
      {!isReportVisible ? (
        <CampaignTable onReportClick={handleReport} />
      ) : (
        <CampaignReport onBackClick={handleBackToTable} selectedCampaign={selectedCampaign} />
      )}
    </div>
  );
};
