import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import './CustomModal.scss'; 

const CustomModal = ({
  isOpen,
  onClose,
  title,
  children,
  showPrimaryButton,
  showSecondaryButton,
  onPrimaryClick,
  onSecondaryClick,
  primaryLabel,
  secondaryLabel,
}) => {
  const buttonCount = (showPrimaryButton ? 1 : 0) + (showSecondaryButton ? 1 : 0);

  return (
    <Modal
      title={<div className="modal-title">{title}</div>}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={
        <div className={`modal-footer modal-footer-${buttonCount}`}>
          {showSecondaryButton && (
            <CustomButton
              variant="secondary"
              size="medium"
              onClick={onSecondaryClick}
            >
              {secondaryLabel}
            </CustomButton>
          )}
          {showPrimaryButton && (
            <CustomButton
              variant="primary"
              size="medium"
              onClick={onPrimaryClick}
            >
              {primaryLabel}
            </CustomButton>
          )}
        </div>
      }
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  showPrimaryButton: PropTypes.bool,
  showSecondaryButton: PropTypes.bool,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
};

CustomModal.defaultProps = {
  title: "Modal Title",
  children: null,
  showPrimaryButton: true,
  showSecondaryButton: false,
  primaryLabel: "Save",
  secondaryLabel: "Cancel",
};

export default CustomModal;
