import React, { useState } from 'react';
import { Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.scss';
import { AvatarIcon } from '../../Assets/Icons/Icons';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { NavLink, useNavigate } from 'react-router-dom';
import { postApi } from '../../Utils/apiService';
import { ADMIN_LOGIN_DETAILS } from '../../Utils/apiPath';
import ToastHelper from '../../Services/ToastHelper';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const navigate = useNavigate();

  const validateLoginField = (name, value, errors) => {
    if (name === "email") {
      if (!value.trim()) {
        errors.email = "Email cannot be empty.";
      } else {
        delete errors.email;
      }
    } else if (name === "password") {
      if (!value.trim()) {
        errors.password = "Password cannot be empty.";
      } else {
        delete errors.password;
      }
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value
    });

    let errors = { ...formErrors };
    validateLoginField(name, value, errors);
    setFormErrors(errors);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    let errors = { ...formErrors };
    validateLoginField(name, value, errors);
    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = { ...formErrors };
    validateLoginField("email", formData.email, errors);
    validateLoginField("password", formData.password, errors);
    setFormErrors(errors);

    if (!errors.email && !errors.password) {
      setIsLoading(true);
      const payload = {
        email: formData.email,
        password: formData.password,
      };

      try {
        const { statusCode, data, message, error } = await postApi(
          ADMIN_LOGIN_DETAILS,
          payload
        );
        if (statusCode === 200) {
          localStorage.setItem("accessToken", data.accessToken);
          setIsLoading(false);
          navigate("/dashboard");
          ToastHelper.success(message);
        } else {
          setIsLoading(false);
          console.error('Error:', error);
          setApiMessage(message);
          ToastHelper.error(error);
        }
      } catch (err) {
        setIsLoading(false);
        console.error('API Error:', err.message);
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="avatar">
          <AvatarIcon fill='#9b00ff' />
        </div>
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}>
          <div className="input-wrapper">
            <Input
              className={`input-field ${formErrors.email ? 'error' : ''}`}
              size="large"
              placeholder="Email"
              prefix={<UserOutlined />}
              name="email"
              value={formData.email}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {formErrors.email && <div className="error-message">{formErrors.email}</div>}
          </div>
          <div className="input-wrapper">
            <Input.Password
              className={`input-field ${formErrors.password ? 'error' : ''}`}
              size="large"
              placeholder="Password"
              prefix={<LockOutlined />}
              name="password"
              value={formData.password}
              onChange={handleFieldChange}
              onBlur={handleBlur}
            />
            {formErrors.password && <div className="error-message">{formErrors.password}</div>}
          </div>
          <div className="remember-forgot">
            <NavLink to="/forgot-password">Forgot Password?</NavLink>
          </div>
          <CustomButton
            variant="secondary"
            block
            className="login-btn"
            style={{ border: "none", width: "60%", backgroundColor: "#ecd2ec" }}
            onClick={handleSubmit}
          >
            <div>
              {isLoading ? "Loading..." : "LOGIN"}
            </div>
          </CustomButton>
          {apiMessage}
        </form>
      </div>
    </div>
  );
};

export default Login;
