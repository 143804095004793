import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import "./ApiKeysTable.scss";
import { Table, Tooltip, Dropdown, Spin, Checkbox, Flex, Card } from "antd";
import { AntDesignOutlined, MoreOutlined } from "@ant-design/icons";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { getApi, postApi } from "../../../Utils/apiService";
import {
    ADD_CAMPAIGNS_FOR_USER,
    DEACTIVATE_APIKEY,
    GET_ACTIVE_USERS,
    GET_ALL_APIKEYS,
    GET_ASSIGNED_CAMPAIGNS,
    GET_UNASSIGNED_CAMPAIGNS,
    INACTIVE_USER,
    REMOVE_CAMPAIGNS_FOR_USER,
} from "../../../Utils/apiPath";
import dayjs from "dayjs";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import useTableHeight from "../../../Services/TableHeight";
import ToastHelper from "../../../Services/ToastHelper";

const ApiKeysTable = ({ onAddClick, onEditClick, recallApi }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApiKey, setSelectedApiKey] = useState(null);
    const [campaignData, setCampaignData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [assignedCampaigns, setAssignedCampaigns] = useState([]);
    const [isAssignMode, setIsAssignMode] = useState(true);
    const [mergedCampaignData, setMergedCampaignData] = useState([]);
    const tableHeight = useTableHeight();

    const debouncedSearch = useCallback(
        debounce((query) => {
            fetchData(pageIndex, pageSize, query);
        }, 500),
        []
    );

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        debouncedSearch(e.target.value);
    };


    const handleDeActivateApiKey = async (apiKeyName, currentIsActiveStatus) => {
        const payload = {
            isActive: !currentIsActiveStatus,
            apiKeyName: apiKeyName,
        };
        const { statusCode, message, error } = await postApi(
            DEACTIVATE_APIKEY,
            payload
        );
        if (statusCode === 200) {
            ToastHelper.success(message);
            fetchData(pageIndex, pageSize);
        } else {
            ToastHelper.error(error);
        }
    };

    const menuItems = (id, record) => [
        {
            key: "edit",
            label: <a onClick={() => onEditClick(record)}>Edit</a>,
        },
        {
            key: "inactive",
            label: <a onClick={() => handleDeActivateApiKey(record.apiKeyName, record.isActive)}>{record.isActive ? "Deactivate" : "Activate"}</a>,
        },
    ];

    const columns = [
        {
            title: "SL",
            dataIndex: "r",
            key: "serialNo",
            width: 3,
            render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
        },
        {
            title: "API Key",
            dataIndex: "apiKeyName",
            key: "apiKeyName",
            width: 30,
        },
        {
            title: "Admin API Endpoint",
            dataIndex: "adminApiEndPoint",
            key: "adminApiEndPoint",
            width: 30,
        },
        {
            title: "User API Endpoint",
            dataIndex: "userApiEndPoint",
            key: "userApiEndPoint",
            width: 30,
        },
        {
            title: "Status(Active/Inactive)",
            dataIndex: "isActive",
            key: "status",
            width: 10,
            render: (isActive) => (isActive ? "Active" : "Inactive"),
        },

        {
            title: "Action",
            key: "operation",
            //   fixed: "right",
            width: 8,
            render: (_, record) => (
                <Dropdown
                    menu={{ items: menuItems(record?.apiKeyId, record) }}
                    trigger={["click"]}
                >
                    <Tooltip title="More actions">
                        <MoreOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
                    </Tooltip>
                </Dropdown>
            ),
        },
    ];

    const fetchData = async (pageIndex, pageSize, searchQuery = "") => {
        setLoading(true);
        try {
            const requestBody = {
                pageIndex: pageIndex - 1,
                pageSize,
                searchString: searchQuery,
            };

            const response = await postApi(GET_ALL_APIKEYS, requestBody);

            if (response.statusCode === 200) {
                setData(response?.data);
                setTotalCount(response?.totalCount);
            } else {
                setData([]);

            }
        } catch (err) {
            setError("Failed to fetch apikey data");
        } finally {
            setLoading(false);
        }
    };

    // console.log(recallApi, data);

    useEffect(() => {
        fetchData(pageIndex, pageSize);
    }, [pageIndex, pageSize, recallApi]);

    const getPageSizeOptions = () => {
        if (totalCount <= 50) {
            return ["10", "20", "50"];
        } else {
            return ["10", "20", "50", "100"];
        }
    };



    return (
        <div className="apikeystable">
            <div className="apikeystable_header">
                <div className="apikeystable_header_left">
                    <h2>Manage API Keys</h2>
                </div>
                <div className="apikeystable_header_right">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <CustomButton
                        variant="primary"
                        size="medium"
                        onClick={onAddClick}
                        icon={<AntDesignOutlined />}
                    >
                        Add
                    </CustomButton>
                </div>
            </div>


            {loading ? (
                <Flex align="center" justify="center" gap="middle">
                    <Spin />
                </Flex>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        current: pageIndex,
                        pageSize,
                        total: totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: getPageSizeOptions(),
                        onChange: (page, size) => {
                            setPageIndex(page);
                            setPageSize(size);
                        },
                    }}
                    scroll={{
                        x: 200,
                        y: tableHeight,
                    }}
                    rowKey="id"
                />
            )}
        </div>

    );
};

export default ApiKeysTable;
